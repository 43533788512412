<template>
    <div :id="'editor' + id" @keyup="keyboardUpAndDown">
        <div class="input-group input-left">
            <input type="text" class="form-control" :class="{errorBorder: !valid}"
                   :value="validatedValueAsString" :title="validatedValueAsString"
                   readonly
                   @click="$parent.expandPanel">
        </div>

        <div class="editor-panel hidden animated" @keyup="setKeySelected">
            <div class="close" @click="$parent.hidePanel(valid)">
                <span class="glyphicon glyphicon-chevron-right"></span>
            </div>
            <div class="row">
                <div class="col-md-7">
                    <label class="field-name">{{title}}</label>
                    <div class="has-feedback">
                        <input :id="'input' + id" v-model="currentValueAsString" type="text"
                               class="form-control" :class="{errorBorder: !valid}" autocomplete="nope" @keyup="search" >
                        <span class="form-control-feedback glyphicon glyphicon-remove" @click="emptyValue"></span>
                        <label v-if="!valid" class="error">{{errorMessage}}</label>
                    </div>
                </div>
            </div>
            <div v-if="subLocations.length > 0" class="row">
                <div class="col-md-7">
                    <div class="panel panel-default panel-list">
                        <div class="panel-heading">{{currentValueAsString}}
                        </div>
                        <div class="list-group">
                            <a v-for="item in subLocations" :key="item.SlID"
                               href="javascript:"
                               class="list-group-item"
                               :class="{ 'selected': item === selected, 'activated': item === activated}" @click="setClickSelected(item)">{{item.SlName}}
                                <span v-if='item === selected'>selected</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
'use strict'
import Vue from 'vue'
import $ from 'jquery'
import _ from 'underscore'
import editorUtils from '@/utils/editorUtils'
import projectionUtils from '@/utils/projectionUtil'

/**
 * Editor with which users can set a value to a property based on the selected item in a list.
 */
export default {
  name: 'subLocationListEditor',
  props: ['field', 'options'],

  data () {
    return {
      id: this._uid,
      currentValue: String,
      errorMessage: null,
      valid: true,
      selected: null,
      activated: null,
      title: String,
      lang: Vue.config.lang,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      },
      source: [],
      originalSource: [],
      value: String,
      currentValueAsString: String
    }
  },

  computed: {
    subLocations: {
      get () {
        let subLocationsFromStore = this.$store.getters.getSubLocations
        // This is used to filter the sublocation list on what has been filled in if there is a exact match show everything
        if (
          this && this.currentValueAsString && (typeof this.currentValueAsString === 'string')) {
          let currentValue = this.currentValueAsString
          return subLocationsFromStore.filter(function (item) {
            return item.SlName.toString().toUpperCase().indexOf(currentValue.toString().toUpperCase()) >= 0
          })
        } else {
          return subLocationsFromStore
        }
      }
    },
    /**
     * Computed property used to get the validated value as it should be shown to the user of the property
     * corresponding to this instance of the ListEditor.
     */
    validatedValueAsString: {
      get () {
        let item = this.getSubLocationFromId(this.validatedValue)

        if (item === 9999) {
          this.setSelected(item)
        }
        return (typeof (item) === 'object' && item.SlName) ? item.SlName : item
      }
    },
    /**
     * Computed property used to get and set the validated value of the property corresponding to this instance of the ListEditor.
     */
    validatedValue: {
      get () {
        let val = editorUtils.getEditorValue(this.field)
        // overwrite current value with 'valid' value from store and set valid to true. get() is called in the following cases:
        // - when the value of this.$store.state.workingObject[this.field.key] changed (e.g. because side effect or switching workingObject)
        // - when initializing the editor
        this.setCurrentValue(val)

        return val
      },
      set (value) {
        let result = editorUtils.setEditorValue(this.field, value)
        if (result.hasChanges) {
          this.$store.commit('updateWorkingObject', result.values)
        }
      }
    },
    /**
     * Computed property used to get and set whether the validation of the current value has finished.
     */
    activeEditorValidationPending: {
      get () {
        return this.$store.state.activeEditorValidationPending
      },
      set (val) {
        this.$store.commit('setActiveEditorValidationPending', val)
      }
    }
  },

  watch: {
    'currentValue': function (value) {
      // set activeEditorValidationPending to true, so that navigation is blocked until validation is done
      this.activeEditorValidationPending = true
      this.validateCurrentValue(this.currentValue)

      let item = this.getSubLocationFromId(this.currentValue)

      this.selected = item || null
      this.activated = item || null
      this.currentValueAsString = (typeof (item) === 'object' && item.SlName) ? item.SlName : ''
    },
    'currentValueAsString': function (inputValue) {
      // only set currentValue is we save currentValue as string. in case we want to save id, we only want to set
      // filter this.source using value currently in input and set activated if case insensitive exact match has
      // been found
      this.source = this.originalSource.filter(v => {
        let value = this.getText(v)

        if (typeof (value) === 'string' && typeof (inputValue) === 'string') {
          // if case insensitive exact match has been found, then set it as activated (highlight it with green)
          if (value.toLowerCase() === inputValue.toLowerCase()) {
            this.setActivated(v, 1)
          }

          return value && value.toLowerCase()
            .indexOf(inputValue.toLowerCase()) !== -1
        } else {
          return false
        }
      })

      // validated value on enter or click on item
      if (this.isSavedAsString) {
        this.currentValue = inputValue
      }
    }
  },

  created () {
    // initiate this.value to the current value
    this.value = this.field.value
  },

  mounted () {
    this.title = this.field.title
  },

  methods: {
    setCurrentValue (value) {
      this.currentValue = value
      this.valid = true
    },
    allowNewEntries () {
      // Allows entries not yet given as choices in the drop-down list shown
      return this.isSavedAsString // These properties are equal for now, but might change in the future
    },
    getChoiceItemMatchValue (source, value) {
      return source.find(v => {
        return v.value === value
      })
    },
    setSelected (item) {
      this.currentValue = item.SlID
    },
    setActivated (item, orientation) {
      this.activated || (this.activated = item)
      this.activated = item
    },
    getTemp (storage) {
      let tempdata = storage.getItem(this.field.key)
      if (!tempdata) {
        tempdata = []
      } else {
        tempdata = tempdata.split(',')
      }
      return tempdata
    },
    validateCurrentValue: _.debounce(function (val) {
      // validate field and value using TerraIndexValidator
      this.$validateEditor(this.field, val)
        .then(() => {
          // set valid to true and set val to validatedValue as validation has succeeded
          this.valid = true
          this.validatedValue = val
        })
        .catch(reason => {
          // extract reason and set valid to false
          this.valid = false
          this.errorMessage = reason.message
        })
        .finally(() => {
          // validation has finished
          this.activeEditorValidationPending = false
        })
    }, 500),
    setClickSelected (item) {
      this.setActivated(item)
      this.setSelected(item)
    },
    emptyValue () {
      this.source = this.originalSource
      this.currentValue = ''
      this.selected = undefined
    },
    setKeySelected (evt) {
      evt = (evt) || window.event
      // 13 is enter key
      if (evt.keyCode && evt.keyCode === 13) {
        this.setSelected(this.activated)
        evt.preventDefault()
      }
    },
    search (evt) {
      evt = (evt) || window.event
      let keyCode = evt.keyCode || evt.which
      const DISABLED_KEYCODES = [37, 38, 39, 40, 13]
      // if current key code is in DISABLED_KEYCODES, then return/exit
      if (DISABLED_KEYCODES.indexOf(keyCode) > -1) {
        evt.preventDefault()
      }
    },
    /**
     * Whether or not to include the item in the list of options.
     * True = include
     */
    includeItem (item) {
      if (this.field.key === 'PrCoordinateSystem') {
        return projectionUtils.isCodeSupported(item.value)
      }
      return true
    },
    focus () {
      $('#input' + this.id)
        .focus()
      this.source = this.originalSource
    },
    unfocus () {
      let item = this.getChoiceItemMatchValue(this.originalSource, this.value)
      if (this.isSavedAsString && this.allowNewEntries()) {
        this.content = this.value
      } else if (item) {
        this.content = item.localizedText
      } else {
        this.content = undefined // undefined;
      }
    },
    getActivatedIndex () {
      if (!this.options.isObject && !this.activated && this.activated !== '') {
        return -1
      }

      if (this.options.isObject && !this.activated) {
        return -1
      }

      let key = this.activated.value || this.activated
      let index = 0

      if (this.options.isObject) {
        index = _.findIndex(this.source, { value: key })
        return index || 0
      }

      index = _.indexOf(this.source, key)

      return index || 0
    },
    gotoNext () {
      let currentIndex = this.getActivatedIndex()
      if (currentIndex + 1 >= this.source.length) {
        return
      } else {
        currentIndex++
      }

      let item = this.source[currentIndex]
      if (item === '' || item) {
        this.setActivated(item, 1)
      }
    },
    gotoPrev () {
      let currentIndex = this.getActivatedIndex()

      if (currentIndex === 0) {
        return
      } else {
        currentIndex--
      }

      let item = this.source[currentIndex]

      if (item === '' || item) {
        this.setActivated(item, 0)
      }
    },
    keyboardUpAndDown (evt) {
      evt = (evt) || window.event
      if (evt.keyCode) {
        if (evt.keyCode === 38) {
          this.gotoPrev()
          evt.preventDefault()
        }
        if (evt.keyCode === 40) {
          this.gotoNext()
          evt.preventDefault()
        }
      }
    },
    /***
     * Find object in this.field.options with value of property localizedText that matches parameter 'value'.
     * @param value The string to be matched with 'option.localizedText'
     */
    getOptionFromString (value) {
      return this.field.options.find(function (option) {
        return option.localizedText && option.localizedText.toString() === value.toString()
      })
    },
    /***
     * Find object in this.field.options with value of property value that matches parameter 'value'.
     * @param value The string to be matched with 'option.value'
     */
    getSubLocationFromId (value) {
      if (value === null) return ''
      if (this.subLocations === null || this.subLocations.length < 0) return value
      return this.subLocations.find((item) => (item.SlID === value))
    }
  }
}
</script>
